<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">
        <app-i18n code="home.menu"></app-i18n>
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        <app-i18n code="settings.title"></app-i18n>
      </el-breadcrumb-item>
    </el-breadcrumb>

    <div class="app-content-page">
      <h1 class="app-content-title">
        <app-i18n code="settings.title"></app-i18n>
      </h1>

      <app-settings-toolbar
        @showDialog="emailTestDialogVisible = true"
      ></app-settings-toolbar>

      <div
        class="app-page-spinner"
        v-if="findLoading"
        v-loading="findLoading"
      ></div>

      <el-form
        label-position="top"
        :label-width="labelWidthForm"
        :model="model"
        :rules="rules"
        @submit.native.prevent="doSubmit"
        class="form"
        ref="form"
        v-if="model"
      >
        <el-tabs v-model="active" type="border-card" tab-position="top">
          <el-tab-pane label="General" name="General">
            <el-tabs>
              <el-tab-pane label="E-mail">
                <div>
                  <el-row :gutter="30">
                    <el-col :md="10" :lg="8" :xl="8">
                      <el-form-item
                        :label="fields.personalDepartmentMailbox.label"
                        :prop="fields.personalDepartmentMailbox.name"
                        :required="fields.personalDepartmentMailbox.required"
                      >
                        <el-input
                          ref="focus"
                          v-model="model[fields.personalDepartmentMailbox.name]"
                        />
                      </el-form-item>

                      <el-form-item
                        :label="fields.contractApprover.label"
                        :prop="fields.contractApprover.name"
                        :required="fields.contractApprover.required"
                      >
                        <app-user-autocomplete-input
                          :fetchFn="fields.contractApprover.fetchFn"
                          :mapperFn="fields.contractApprover.mapperFn"
                          mode="multiple"
                          v-model="model[fields.contractApprover.name]"
                        ></app-user-autocomplete-input>
                      </el-form-item>
                      <el-form-item
                        :label="fields.beendigungApprover.label"
                        :prop="fields.beendigungApprover.name"
                        :required="fields.beendigungApprover.required"
                      >
                        <app-user-autocomplete-input
                          :fetchFn="fields.beendigungApprover.fetchFn"
                          :mapperFn="fields.beendigungApprover.mapperFn"
                          mode="multiple"
                          v-model="model[fields.beendigungApprover.name]"
                        ></app-user-autocomplete-input>
                      </el-form-item>
                      <el-form-item
                        :label="fields.externeLohnverrechnungEmail.label"
                        :prop="fields.externeLohnverrechnungEmail.name"
                        :required="fields.externeLohnverrechnungEmail.required"
                      >
                        <el-input
                          ref="focus"
                          v-model="
                            model[fields.externeLohnverrechnungEmail.name]
                          "
                        />
                      </el-form-item>
                      <el-form-item
                        :label="fields.externeLohnverrechnungEmailCC.label"
                        :prop="fields.externeLohnverrechnungEmailCC.name"
                        :required="
                          fields.externeLohnverrechnungEmailCC.required
                        "
                      >
                        <el-select
                          :no-data-text="i18n('user.new.emailsHint')"
                          allow-create
                          default-first-option
                          filterable
                          multiple
                          placeholder
                          ref="focus"
                          v-model="
                            model[fields.externeLohnverrechnungEmailCC.name]
                          "
                        ></el-select>
                      </el-form-item>
                      <el-form-item
                        :label="fields.arbeitsmedizinEmail.label"
                        :prop="fields.arbeitsmedizinEmail.name"
                        :required="fields.arbeitsmedizinEmail.required"
                      >
                        <el-input
                          ref="focus"
                          v-model="model[fields.arbeitsmedizinEmail.name]"
                        />
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
              </el-tab-pane>

              <el-tab-pane label="Server Einstellungen">
                <div>
                  <el-row :gutter="30">
                    <el-col :span="4" :offset="0">
                      <el-form-item
                        :label="fields.lieferungDerAenderungen.label"
                        :prop="fields.lieferungDerAenderungen.name"
                        :required="fields.lieferungDerAenderungen.required"
                      >
                        <el-input-number
                          :controls="false"
                          :max="fields.lieferungDerAenderungen.max"
                          :min="fields.lieferungDerAenderungen.min"
                          :precision="fields.lieferungDerAenderungen.scale"
                          v-model="model[fields.lieferungDerAenderungen.name]"
                        ></el-input-number>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
              </el-tab-pane>
              <el-tab-pane label="Auswertung">
                <div>
                  <el-row :gutter="30">
                    <el-col :span="4" :offset="0">
                      <el-form-item
                        :label="fields.urlaubsgeldMonat.label"
                        :prop="fields.urlaubsgeldMonat.name"
                        :required="fields.urlaubsgeldMonat.required"
                      >
                        <el-input-number
                          :controls="false"
                          :max="fields.urlaubsgeldMonat.max"
                          :min="fields.urlaubsgeldMonat.min"
                          :precision="fields.urlaubsgeldMonat.scale"
                          v-model="model[fields.urlaubsgeldMonat.name]"
                        ></el-input-number>
                      </el-form-item>
                      <el-form-item
                        :label="fields.weihnachtsgeldMonat.label"
                        :prop="fields.weihnachtsgeldMonat.name"
                        :required="fields.weihnachtsgeldMonat.required"
                      >
                        <el-input-number
                          :controls="false"
                          :max="fields.weihnachtsgeldMonat.max"
                          :min="fields.weihnachtsgeldMonat.min"
                          :precision="fields.weihnachtsgeldMonat.scale"
                          v-model="model[fields.weihnachtsgeldMonat.name]"
                        ></el-input-number>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
              </el-tab-pane>
              <div class="mitarbeiter-form-buttons">
                <el-button
                  :disabled="saveLoading"
                  @click="doSubmit"
                  icon="el-icon-fa-floppy-o"
                  type="primary"
                >
                  <app-i18n code="common.save"></app-i18n>
                </el-button>
                <router-link :to="{ path: '/' }">
                  <el-button :disabled="saveLoading" icon="el-icon-fa-close">
                    <app-i18n code="common.cancel"></app-i18n>
                  </el-button>
                </router-link>
              </div>
            </el-tabs>
          </el-tab-pane>
          <el-tab-pane label="SV" name="SV">
            <el-row :gutter="20">
              <el-col>
                <app-sv-werte-form-page
                  :isEditing="isEditing"
                  :svWerte="svWerte"
                  :saveLoading="saveLoading"
                  v-if="!findLoading"
                />
              </el-col>
            </el-row>
          </el-tab-pane>
        </el-tabs>
      </el-form>

      <app-settings-email-test
        :visible="emailTestDialogVisible"
        v-if="emailTestDialogVisible"
        @success="doSendEmailTest"
        @close="emailTestDialogVisible = !emailTestDialogVisible"
      ></app-settings-email-test>
    </div>
  </div>
</template>

<script>
import { SettingsModel } from '@/modules/settings/settings-model';
import { mapGetters, mapActions } from 'vuex';
import { i18n } from '@/i18n';
import { FormSchema } from '@/shared/form/form-schema';
import SettingsToolbar from '@/modules/settings/components/settings-toolbar.vue';
import SettingsEmailTestModal from '@/modules/settings/components/settings-email-test.vue';
import SvWerteFormPage from '@/modules/settings/components/sv-werte-form-page.vue';

const { fields } = SettingsModel;
const formSchema = new FormSchema([
  fields.contractApprover,
  fields.beendigungApprover,
  fields.externeLohnverrechnungEmail,
  fields.externeLohnverrechnungEmailCC,
  fields.personalDepartmentMailbox,
  fields.arbeitsmedizinEmail,
  fields.lieferungDerAenderungen,
  fields.urlaubsgeldMonat,
  fields.weihnachtsgeldMonat,
  fields.updatedAt,
]);

export default {
  name: 'app-settings-page',

  components: {
    [SettingsToolbar.name]: SettingsToolbar,
    [SvWerteFormPage.name]: SvWerteFormPage,
    [SettingsEmailTestModal.name]: SettingsEmailTestModal,
  },

  data() {
    return {
      rules: formSchema.rules(),
      model: null,
      active: 'General',
      emailTestDialogVisible: false,
    };
  },

  computed: {
    ...mapGetters({
      labelPosition: 'layout/labelPosition',
      labelWidthForm: 'layout/labelWidthForm',
      settings: 'settings/settings',
      svWerte: 'settings/svWerte',
      findLoading: 'settings/findLoading',
      saveLoading: 'settings/saveLoading',
    }),

    fields() {
      return fields;
    },

    isEditing() {
      return !!this.settings;
    },
  },

  async created() {
    await this.doFind();
    this.model = formSchema.initialValues(this.settings);
  },

  methods: {
    ...mapActions({
      doFind: 'settings/doFind',
      doSave: 'settings/doSave',
      doSaveSvWerte: 'settings/doSaveSvWerte',
      dUpdateSvWerte: 'settings/doUpdateSvWerte',
      doSendEmailTestStore: 'settings/doSendEmailTest',
    }),

    doCancel() {
      //close dialog
    },

    async doSubmit() {
      try {
        await this.$refs.form.validate();
      } catch (error) {
        return;
      }

      const values = formSchema.cast(this.model);
      return this.doSave(values);
    },

    i18n(code) {
      return i18n(code);
    },

    async doSendEmailTest(payload) {
      try {
        this.emailTestDialogVisible = false;

        return this.doSendEmailTestStore(payload);
      } catch (error) {
        //no
      }
    },
  },
};
</script>

<style>
.settings-box {
  float: left;
  width: 20px;
  height: 20px;
  margin: 5px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 50%;
}
</style>
