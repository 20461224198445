import { i18n } from '@/i18n';
import { GenericModel } from '@/shared/model/generic-model';
import Storage from '@/security/storage';
import Permissions from '@/security/permissions';
import DateTimeField from '@/shared/fields/date-time-field';
import EmailsField from '@/shared/fields/emails-field';
import FilesField from '@/shared/fields/files-field';

function label(name) {
  return i18n(`settings.fields.${name}`);
}

const fields = {
  email: new EmailsField('email', label('email'), {
    required: true,
  }),
  emailTestAttachment: new FilesField(
    'emailTestAttachment',
    label('emailTestAttachment'),
    Storage.values.settingsEmailTestAttachment,
    Permissions.getFilePermissions('settingsFileFields', 'emailTestAttachment'),
    {
      size: 1048576,
      formats: ['pdf', 'doc', 'docx'],
      max: 1,
      required: true,
    },
  ),
  updatedAt: new DateTimeField('updatedAt', label('updatedAt')),
};

export class SettingsEmailTestModel extends GenericModel {
  static get fields() {
    return fields;
  }
}
