import { i18n } from '@/i18n';
import { UserField } from '@/modules/user/user-field';
import { GenericModel } from '@/shared/model/generic-model';
import StringField from '@/shared/fields/string-field';
import DateTimeField from '@/shared/fields/date-time-field';
import MoneyField from '@/shared/fields/money-field';
import IntegerField from '@/shared/fields/integer-field';
import DecimalField from '@/shared/fields/decimal-field';
import EmailsField from '@/shared/fields/emails-field';

function label(name) {
  return i18n(`settings.fields.${name}`);
}

const fields = {
  email: new EmailsField('email', label('email'), {
    required: true,
  }),
  contractApprover: UserField.relationToMany(
    'contractApprover',
    label('contractApprover'),
    {},
  ),
  externeLohnverrechnungEmail: new StringField(
    'externeLohnverrechnungEmail',
    label('externeLohnverrechnungEmail'),
    {
      required: false,
      matches:
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    },
  ),
  externeLohnverrechnungEmailCC: new EmailsField(
    'externeLohnverrechnungEmailCC',
    label('externeLohnverrechnungEmailCC'),
    {
      required: false,
    },
  ),

  beendigungApprover: UserField.relationToMany(
    'beendigungApprover',
    label('beendigungApprover'),
    {},
  ),
  personalDepartmentMailbox: new StringField(
    'personalDepartmentMailbox',
    label('personalDepartmentMailbox'),
    {
      required: false,
      matches:
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    },
  ),
  arbeitsmedizinEmail: new StringField(
    'arbeitsmedizinEmail',
    label('arbeitsmedizinEmail'),
    {
      required: false,
      matches:
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    },
  ),
  geringfuegigkeitsgrenze: new MoneyField(
    'geringfuegigkeitsgrenze',
    label('geringfuegigkeitsgrenze'),
    {
      scale: 2,
      min: 0,
      max: 100000,
      required: false,
    },
  ),
  hbglProTag: new MoneyField('hbglProTag', label('hbglProTag'), {
    scale: 2,
    min: 0,
    max: 100000,
    required: false,
  }),
  hbglProMonat: new MoneyField('hbglProMonat', label('hbglProMonat'), {
    scale: 2,
    min: 0,
    max: 100000,
    required: false,
  }),
  hbglProJahr: new MoneyField('hbglProJahr', label('hbglProJahr'), {
    scale: 2,
    min: 0,
    max: 100000,
    required: false,
  }),
  lieferungDerAenderungen: new IntegerField(
    'lieferungDerAenderungen',
    label('lieferungDerAenderungen'),
    {
      required: true,
    },
  ),
  sv: new DecimalField('sv', label('sv'), {
    scale: 2,
    min: 0,
    max: 100,
    required: false,
  }),
  sv_60_bis_63: new DecimalField('sv_60_bis_63', label('sv_60_bis_63'), {
    scale: 2,
    min: 0,
    max: 100,
    required: false,
  }),
  sv_uber_63: new DecimalField('sv_uber_63', label('sv_uber_63'), {
    scale: 2,
    min: 0,
    max: 100,
    required: false,
  }),
  sv_sz: new DecimalField('sv_sz', label('sv_sz'), {
    scale: 2,
    min: 0,
    max: 100,
    required: false,
  }),
  sv_sz_60_bis_63: new DecimalField(
    'sv_sz_60_bis_63',
    label('sv_sz_60_bis_63'),
    {
      scale: 2,
      min: 0,
      max: 100,
      required: false,
    },
  ),
  sv_sz_uber_63: new DecimalField('sv_sz_uber_63', label('sv_sz_uber_63'), {
    scale: 2,
    min: 0,
    max: 100,
    required: false,
  }),
  sv_geringfuegig: new DecimalField(
    'sv_geringfuegig',
    label('sv_geringfuegig'),
    {
      scale: 2,
      min: 0,
      max: 100,
      required: false,
    },
  ),
  sv_geringfuegig_uber_60: new DecimalField(
    'sv_geringfuegig_uber_60',
    label('sv_geringfuegig_uber_60'),
    {
      scale: 2,
      min: 0,
      max: 100,
      required: false,
    },
  ),
  db: new DecimalField('db', label('db'), {
    scale: 2,
    min: 0,
    max: 100,
    required: false,
  }),
  db_uber_60: new DecimalField('db_uber_60', label('db_uber_60'), {
    scale: 2,
    min: 0,
    max: 100,
    required: false,
  }),
  dz: new DecimalField('dz', label('dz'), {
    scale: 2,
    min: 0,
    max: 100,
    required: false,
  }),
  bv: new DecimalField('bv', label('bv'), {
    scale: 2,
    min: 0,
    max: 100,
    required: false,
  }),
  koSt: new DecimalField('koSt', label('koSt'), {
    scale: 2,
    min: 0,
    max: 100,
    required: false,
  }),
  urlaubsgeldMonat: new IntegerField(
    'urlaubsgeldMonat',
    label('urlaubsgeldMonat'),
  ),
  weihnachtsgeldMonat: new IntegerField(
    'weihnachtsgeldMonat',
    label('weihnachtsgeldMonat'),
  ),

  updatedAt: new DateTimeField('updatedAt', label('updatedAt')),
};

export class SettingsModel extends GenericModel {
  static get fields() {
    return fields;
  }
}
