<template>
  <div class="app-page-toolbar">
    <router-link
      :to="{ path: '/audit-logs', query: { entityNames: 'settings' } }"
      v-if="hasPermissionToAuditLogs"
    >
      <el-button icon="el-icon-fa-history">
        <app-i18n code="auditLog.menu"></app-i18n>
      </el-button>
    </router-link>
    <el-tooltip :content="emailTestButtonTooltip">
      <span>
        <el-button @click="$emit('showDialog')" icon="el-icon-top">
          <app-i18n code="common.emailTest"></app-i18n>
        </el-button>
      </span>
    </el-tooltip>
  </div>
</template>

<script>
import { AuditLogPermissions } from '@/modules/audit-log/audit-log-permissions';
import { mapGetters } from 'vuex';
import { i18n } from '@/i18n';

export default {
  name: 'app-settings-toolbar',

  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
    }),

    hasPermissionToAuditLogs() {
      return new AuditLogPermissions(this.currentUser).read;
    },

    emailTestButtonTooltip() {
      return !this.hasRows ? i18n('common.emailTest') : null;
    },
  },
};
</script>

<style></style>
