<template>
  <div>
    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      width="30%"
    >
      <el-form
        :label-position="labelPosition"
        :label-width="labelWidthForm"
        :model="model"
        @submit.native.prevent="doSubmit"
        class="form"
        ref="form"
        v-if="model"
      >
        <el-form-item
          :label="fields.email.label"
          :prop="fields.email.name"
          :required="fields.email.required"
        >
          <el-input
            :placeholder="fields.email.label"
            autocomplete="off"
            ref="focus"
            type="text"
            v-model="model[fields.email.name]"
          ></el-input>
        </el-form-item>
        <el-form-item
          :label="fields.emailTestAttachment.label"
          :prop="fields.emailTestAttachment.name"
          :required="fields.emailTestAttachment.required"
        >
          <app-file-upload
            :max="fields.emailTestAttachment.max"
            :storage="fields.emailTestAttachment.storage"
            :formats="fields.emailTestAttachment.formats"
            :permissions="fields.emailTestAttachment.permissions"
            v-model="model[fields.emailTestAttachment.name]"
          ></app-file-upload>
        </el-form-item>
        <el-form-item>
          <div class="form-buttons">
            <el-button
              @click="doSubmit"
              icon="el-icon-fa-floppy-o"
              type="primary"
            >
              <app-i18n code="common.submit"></app-i18n>
            </el-button>

            <el-button @click="doCancel" icon="el-icon-fa-close">
              <app-i18n code="common.cancel"></app-i18n>
            </el-button>
          </div>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { FormSchema } from '@/shared/form/form-schema';
import { SettingsEmailTestModel } from '@/modules/settings/settings-email-test-model';
import { i18n } from '@/i18n';

const { fields } = SettingsEmailTestModel;
const formSchema = new FormSchema([fields.email, fields.emailTestAttachment]);

export default {
  name: 'app-settings-email-test',

  props: ['visible'],

  data() {
    return {
      model: {},
    };
  },

  computed: {
    ...mapGetters({
      labelPosition: 'layout/labelPosition',
      labelWidthForm: 'layout/labelWidthForm',
    }),

    dialogVisible: {
      get: function () {
        return this.visible;
      },

      set: function (value) {
        if (!value) {
          this.$emit('close');
        }
      },
    },

    formSchema() {
      return formSchema;
    },

    title() {
      return i18n('common.emailTest');
    },

    fields() {
      return fields;
    },
  },

  methods: {
    async doSubmit() {
      try {
        await this.$refs.form.validate();
      } catch (error) {
        return;
      }
      const data = formSchema.cast(this.model);
      return this.$emit('success', data);
    },

    doCancel() {
      this.$emit('close');
    },

    i18n(code) {
      return i18n(code);
    },
  },
};
</script>
